:root {
  --secondary: #00bcd4;
  --primary: #22668c;
  --primary-light: #e6fdff;
  --text-color: #263238;
  --red-primary: #ee666e;
  --yellow-secondary: #f9c24e;
  --yellow-primary: #b87e00;
  --green-primary: #4fab60;
  --green-secondary: #73c38b;
}

p,
h1,
h2,
h3,
h4,
h5 {
  color: var(--text-color);
}

.text-primary {
  color: var(--primary) !important;
}
.text-secondary {
  color: var(--secondary) !important;
}
.text-primary-light {
  color: var(--primary-light) !important;
}
.text {
  color: var(--text-color) !important;
}

/* background */
.bg-primary {
  background-color: var(--primary) !important;
}

.bg-primary-light {
  background-color: var(--primary-light) !important;
}

.bg-yellow-primary {
  background-color: var(--yellow-primary) !important;
}

.bg-yellow-secondary {
  background-color: var(--yellow-secondary) !important;
}

.bg-red-primary {
  background-color: var(--red-primary) !important;
}

.bg-green-primary {
  background-color: var(--green-primary) !important;
}

.bg-green-secondary {
  background-color: var(--green-secondary) !important;
}

/* borders */
.round {
  border-radius: 1rem !important;
}

.round-full {
  border-radius: 4rem;
}

/* bolds */

.b-500 {
  font-weight: 500;
}
.b-600 {
  font-weight: 600;
}

.b-700 {
  font-weight: 700;
}

/* anchors */
.link-n {
  text-decoration: none !important;
  color: white;
}

/* cursor */
.c-p {
  cursor: pointer;
}
