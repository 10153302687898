@import "~antd/dist/antd.css";

/* * {
  outline: 1px solid red;
} */

body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
}

*:not(input, textarea, .CodeMirror, .es, .enable-select > *) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
